import React from "react"
import styled from "styled-components"
import { Layout, Promotions } from "../components"

const LocationPage = () => {
  return (
    <Wrapper>
      <Layout>
        <Promotions />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-grey-10);

  nav {
    background: var(--bcg-clr-primary);
  }
`

export default LocationPage
